import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import ApiService from "../apiService/ApiService";
import TextField from "@mui/material/TextField";

function Locations(props) {
    const [open, setOpen] = useState(false);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [inputValue, setInputValue] = useState("");

    const handleSelectedLocation = (location) => {
        setSelectedLocation(location);
        props.setSelectedLocation(location);
    }

    const filterOptions = (options, { inputValue }) => {
        const inputValueLowerCase = inputValue.toLowerCase();
    
        return options.filter((option) => {
          const label = option.city
            ? `${option.city} (${option.country_code})`
            : `${option.country} (${option.country_code})`;
    
          return label.toLowerCase().startsWith(inputValueLowerCase);
        });
    };

    useEffect(() => {
        ApiService.get("api/v1/auth/locations/")
          .then((response) => {
            setLocations(response.data);
          })
          .catch((error) => {
            console.error("Error fetching locations: ", error);
          });
    }, []);

    return (
        <Autocomplete
            id="combo-box"
            open={open && selectedLocation === null}
            onInputChange={(_, value) => {
            setInputValue(value);
            setOpen(value.length > 0);
            }}
            onClose={() => setOpen(false)}
            sx={{ width: "100%" }}
            value={selectedLocation}
            onChange={(event, newValue) => {
            handleSelectedLocation(newValue);
            setOpen(false);
            }}
            options={locations}
            getOptionLabel={(option) =>
            option.city
                ? `${option.city} (${option.country_code})`
                : `${option.country} (${option.country_code})`
            }
            filterOptions={filterOptions}
            renderOption={(props, option) => (
            <div {...props}>
                <img
                src={option.image}
                style={{ marginRight: "8px" }}
                alt="Country"
                />
                {option.city
                ? `${option.city} (${option.country_code})`
                : `${option.country} (${option.country_code})`}
            </div>
            )}
            renderInput={(params) => (
              <TextField
              {...params}
              placeholder="Search"
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#34cb65", // Green border on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#34cb65", // Green border on focus
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#000", // Black text color
                },
              }}
            />
            )}
        />
    )
}

export default Locations;