import React, { useState } from "react";
import { Tabs, Tab, TextField, Button, Box, Typography } from "@mui/material";
import Locations from "../vnLocations/locations";
import ApiService from "../apiService/ApiService";
import styles from "./UploadVNModal.module.css";

const UploadVNModal = (props) => {
  const [uploadMode, setUploadMode] = useState(0);
  const [virtualNumber, setVirtualNumber] = useState("");
  const [orgId, setOrgId] = useState("");
  const [location, setLocation] = useState(null);
  const [file, setFile] = useState([]);

  const formatFileSize = (number) => {
    if (number < 1024) {
      return `${number} bytes`;
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} KB`;
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} MB`;
    }
  };

  const handleAddVN = (mode) => {
    const payload = {
      virtual_number: virtualNumber,
      location: location?.id || null,
      org_id: Number(orgId) || null,
    };

    const formData = new FormData();
    if (mode === "file" && file.length !== 0) {
      formData.append("file", file[0]);
      formData.append("location", location?.id || null);
      if (orgId) formData.append("org_id", Number(orgId));
    }

    ApiService.post(
      "/api/v1/dashboard/add-vns/",
      mode === "single" ? payload : formData
    )
      .then((res) => {
        props.handleFeedback(res);
        props.fetchData();
      })
      .catch((err) => {
        console.error(err);
        props.handleFeedback(err.response);
      })
      .finally(() => {
        props.onClose();
        setVirtualNumber("");
        setLocation(null);
        setFile([]);
      });
  };

  return (
    <div className={styles.modalBody}>
      {/* Tabs for Add Single Number and Upload File */}
      <Tabs
          value={uploadMode}
          onChange={(e, val) => setUploadMode(val)}
          centered
          sx={{
            marginBottom: 2,
            "& .MuiTabs-indicator": {
              backgroundColor: "#34cb65 !important", // Green indicator
            },
            "& .MuiTab-root": {
              color: "#888", // Default unselected tab color
            },
            "& .Mui-selected": {
              color: "#34cb65 !important", // Green text for selected tab
              fontWeight: "bold",
            },
            "& .MuiTab-root:hover": {
              color: "#34cb65", // Green text on hover
            },
          }}
        >
  <Tab label="Add Single Number" />
  <Tab label="Upload File" />
</Tabs>


      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: "1rem", mt: 3 }}
      >
        {/* Org ID and Location Fields */}
        <Box display="flex" gap={3} alignItems="center">
          {/* Org ID Field */}
          <Box sx={{ width: "100%" }}>
            <Typography variant="body2" mb={1}>
              Org ID (Optional)
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={orgId}
              onChange={(e) => setOrgId(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "30px",
                  "&:hover fieldset": {
                    borderColor: "#34cb65", // Green border on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#34cb65", // Green border on focus
                  },
                },
                "& .MuiInputBase-input": {
                  padding: "10px",
                  fontSize: "14px",
                  color: "#000", // Black text color
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#34cb65", // Green label on focus
                },
              }}
            />
          </Box>

          {/* Location Field */}
          <Box sx={{ width: "100%" }}>
            <Typography variant="body2" mb={1}>
              Location
            </Typography>
            <Locations
              setSelectedLocation={setLocation}
            />
          </Box>
        </Box>

        {/* Conditional Rendering for Virtual Number or File Upload Field */}
        {uploadMode === 0 ? (
          <Box>
            <Typography variant="body2" mb={1}>
              Virtual Number
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={virtualNumber}
              onChange={(e) => setVirtualNumber(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                  "&:hover fieldset": {
                    borderColor: "#34cb65", // Green border on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#34cb65", // Green border on focus
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#000", // Black text color
                },
              }}
            />
          </Box>
        ) : (
          <Box>
            <label htmlFor="upload-list-file" className={styles.uploadFileLabel}>
              {file.length === 0 ? (
                <div className={styles.noFile}>
                  <Typography
                    variant="body2"
                    sx={{ color: "#34cb65", fontWeight: "bold" }}
                  >
                    Click to upload or drag and drop
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "0.9rem", color: "#888" }}
                  >
                    File format - csv (max file size 10MB)
                  </Typography>
                </div>
              ) : (
                <div className={styles.fileSelected}>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "0.95rem", fontWeight: "bold" }}
                  >
                    Selected File:{" "}
                    <span style={{ color: "#34cb65" }}>{file[0]?.name}</span> (
                    {formatFileSize(file[0]?.size)})
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    onClick={() => setFile([])}
                    sx={{ marginTop: "8px" }}
                  >
                    Remove File
                  </Button>
                </div>
              )}
              <input
                id="upload-list-file"
                type="file"
                accept=".csv"
                onChange={(e) => {
                  const _file = e.target.files[0];
                  if (_file?.type === "text/csv") {
                    setFile([_file]);
                  } else {
                    setFile([]);
                  }
                }}
                hidden
              />
            </label>
          </Box>
        )}

        {/* Action Buttons */}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button
            variant="outlined"
            color="success"
            onClick={props.onClose}
            sx={{ color: "#34cb65" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleAddVN(uploadMode === 0 ? "single" : "file")}
            disabled={
              (uploadMode === 0 && (!virtualNumber || !location)) ||
              (uploadMode === 1 && (file.length === 0 || !location))
            }
            sx={{ backgroundColor: "#34cb65" }}
          >
            {uploadMode === 0 ? "Add" : "Upload"}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default UploadVNModal;
