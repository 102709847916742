import React, { useEffect, useState } from "react";
import { CircularProgress, Input } from "@mui/material";
import { useParams } from "react-router-dom";
import ApiService from "../../../../components/apiService/ApiService";
import styles from "./billingDetails.module.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import parentStyles from "../Details/details.module.css";
import CustomSnackbar from "../../../../components/ui/CustomSnackbar/customSnackbar";
import CustomModal from "../../../../components/ui/CustomModal/customModal";
import { Button, FormControl } from "@mui/material";
import Locations from "../../../../components/vnLocations/locations";
import DownloadIcon from "@mui/icons-material/Download";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function BillingDetails(props) {
  let { accountId } = useParams();

  const [details, setDetails] = useState(null);
  const [planLicenses, setPlanLicenses] = useState("");
  const [status, setStatus] = useState("");
  const [paymentDueDate, setPaymentDueDate] = useState("");
  const [subStartDate, setSubStartDate] = useState("");
  const [subEndDate, setSubEndDate] = useState("");
  const [chargebeeID, setChargebeeID] = useState("");
  const [chargebeeCustomerID, setChargebeeCustomerID] = useState("")
  const [credits, setCredits] = useState(null);
  const [discounts, setDiscounts] = useState({});
  const [trialLocation, setTrialLocation] = useState(null);
  const [response, setResponse] = useState({});
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [updatePayload, setUpdatePayload] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [showManageModal, setShowManageModal] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState();
  const [isTrialPermission,setIsTrialPermission] = useState()
  const [isSubscriptionPermission,setIsSubscriptionPermission] = useState()
  const [plan ,setPlan]= useState();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const handleDiscardChanges = () => {
    updateDetails(details);
    setDisabled(true);
    setUpdatePayload({});
    setSaveButtonEnabled(false);
  };
  const handleManageModal = () => {
    setShowManageModal(!showManageModal);
  };

  const handleInitiateTrial = () => {
    const locationId = trialLocation ? trialLocation.id : null;
    const payload = {
      location: locationId,
    };

    ApiService.post(`api/v1/dashboard/initiate-trial/${accountId}/`, payload)
      .then((response) => {
        handleFeedback(response);
        setShowTrialModal(false);
      })
      .catch((response) => {
        handleFeedback(response.success);
      });
  };
  console.log(isTrialPermission)

  const handleFeedback = (response) => {
    if (response) {
      setResponse(response.data);
    } else {
      setResponse({
        message: "Unexpected Error. Please Try Again",
        success: false,
      });
    }
    setShowSnackbar(true);
  };

  const handleSaveChanges = () => {
    ApiService.patch(
      `/api/v1/dashboard/update-subscription/${accountId}/`,
      updatePayload,
    )
      .then((res) => {
        setDisabled(true);
        setCredits(res?.data?.credit?.balance);
        setSubEndDate(res?.data?.end_date);
        setUpdatePayload({});
        setDiscounts(res?.data?.discounts || discounts);
        setUpdatePayload({});
        setSaveButtonEnabled(false);
        setShowSnackbar(true);
        setResponse({
          message: "Subscription updated successfully",
          success: true,
        });
      })
      .catch((error) => {
        handleFeedback(error.response);
      });
  };

  const handleInputChange = (category, key, value) => {
    const temp = {
      ...updatePayload,
      discounts: {
        ...updatePayload[discounts],
        [key]: parseFloat(value),
      },
    };
    setUpdatePayload(temp);
    setDiscounts((prevData) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [key]: parseFloat(value),
      },
    }));
  };

  const updateDetails = (data) => {
    setPaymentDueDate(data?.payment_due_date);
    setSubStartDate(data?.start_date);
    setSubEndDate(data?.end_date);
    setCredits(data?.credit?.balance);
    setStatus(data?.status);
    setChargebeeID(data?.chargebee_id);
    setChargebeeCustomerID(data?.chargebee_customer_id)
    const licensesData = {};
    data?.licenses.forEach((license) => {
      licensesData[license.type] = {
        total: license.total,
        free: license.free,
        deleted: license.deleted,
        discountedPrice: license.discounted_price,
        proratedPrice: license.prorated_price,
      };
    });
    setPlanLicenses(licensesData);
    setDiscounts(data?.discounts);
    setPlan(data?.plan);
  };
  const handleUpdateLicenses = () => {
    const payload = {
      action: selectedAction,
      license: selectedLicense,
      quantity: selectedQuantity,
    };

    ApiService.post(`/api/v1/dashboard/update-licenses/${accountId}/`, payload)
      .then((response) => {
        handleFeedback(response);
        setShowManageModal(false);
        setSelectedAction("");
        setSelectedQuantity("");
        setSelectedLicense("");
      })
      .catch((error) => {
        handleFeedback(error.response);
      });
  };

  const handleDownloadCreditUsageReport = async () => {
    setShowLoader(true);
    try{
      const res = await ApiService.get(`/api/v1/dashboard/download-report/${accountId}/?date_start=${startDate}&date_end=${endDate}&report=credit`);
      if(res.data.success){
        handleFeedback(res);
      }
      else{
        throw new Error(res.data.message || 'Error while generating credit usage report');
      }
    }
    catch(err){
      handleFeedback(err.response);
    }
    finally{
      setShowLoader(false);
    }
  }

  useEffect(() => {
    const fetchData = (showFeedback = true) => {
      setShowLoader(true);
      ApiService.get(`/api/v1/dashboard/retrieve-subscription/${accountId}/`)
        .then((res) => {
          if (res.data.success) {
            setDetails(res.data.data);
            updateDetails(res.data.data);
            const trialPermission = res.data.data['trial_subscription.can_write']; 
            setIsTrialPermission(trialPermission);
            const subscriptionPermission = res.data.data['subscriptions.can_write'];
            setIsSubscriptionPermission(subscriptionPermission);
            const today = Date.now();
            setStartDate(res.data.data?.start_date || new Date(today - 7 * 864000000));
            setEndDate(res.data.data?.end_date || new Date(today));
          }
          setShowLoader(false);
          if (showFeedback) handleFeedback(res);
        })
        .catch((err) => {
          setShowLoader(false);
          handleFeedback(err.response);
        });
    };
    fetchData();
  }, [accountId]);
  return (
    <div>
      {showLoader ? (
        <div className={styles.loader_wrapper}>
          <CircularProgress color="success" size={60} />
        </div>
      ) : (
        <div className={parentStyles.settings}>
          <div className={parentStyles.accountSettings}>
            <label className={parentStyles.mainheading}>Subscription</label>
            <div className={parentStyles.property}>
              <label>Plan</label>
              <select
                value={plan || ''}
                onChange={(e) => {
                  setDisabled(false);
                  setPlan(e.target.value)
                  const temp = updatePayload;
                  temp["plan"] = e.target.value;
                  setUpdatePayload(temp);
                }}
                disabled = {!isSubscriptionPermission}
              >
              <option value="" disabled hidden>None</option>
              <>
                <option value="Standard-INR-Monthly">Standard-INR-Monthly</option>
                <option value="Standard-INR-Every-3-months">Standard-INR-Every-3-months</option>
                <option value="Standard-INR-Yearly">Standard-INR-Yearly</option>
                <option value="Professional-INR-Monthly">Professional-INR-Monthly</option>
                <option value="Professional-INR-Every-3-months">Professional-INR-Every-3-months</option>
                <option value="Professional-INR-Yearly">Professional-INR-Yearly</option>
                <option value="Standard-USD-Monthly">Standard-USD-Monthly</option>
                <option value="Standard-USD-Every-3-months">Standard-USD-Every-3-months</option>
                <option value="Standard-USD-Yearly">Standard-USD-Yearly</option>
                <option value="Professional-USD-Monthly">Professional-USD-Monthly</option>
                <option value="Professional-USD-Every-3-months">Professional-USD-Every-3-months</option>
                <option value="Professional-USD-Yearly">Professional-USD-Yearly</option>
              </>
              </select>
            </div>
            <div className={parentStyles.property}>
              <label>Plan Status</label>
                <select
                  size="small"
                  value={status}
                  onChange={(e) => {
                    setDisabled(false);
                    setStatus(e.target.value);
                    const temp = updatePayload;
                    temp["status"] = e.target.value;
                    setUpdatePayload(temp);
                    setDisabled(false);
                  }}
                  disabled = {!isSubscriptionPermission}
                >
                  <option value="TRIAL">TRIAL</option>
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>
                </select>
            </div>
            <div className={parentStyles.property}>
              <label>Start date</label>
              <div className={parentStyles.clm}>
                <input
                  type="date"
                  name="subs_start_date"
                  value={subStartDate}
                  onChange={(e) => {
                    setSubStartDate(e.target.value);
                    const temp = updatePayload;
                    temp["start_date"] = e.target.value;
                    setUpdatePayload(temp);
                    setDisabled(false);
                  }}
                  disabled = {!isSubscriptionPermission}
                />
              </div>
            </div>
            <div className={parentStyles.property}>
              <label>End date</label>
              <div className={parentStyles.clm}>
                <input
                  type="date"
                  name="subs_end_date"
                  value={subEndDate}
                  onChange={(e) => {
                    setSubEndDate(e.target.value);
                    const temp = updatePayload;
                    temp["end_date"] = e.target.value;
                    setUpdatePayload(temp);
                    setDisabled(false);
                  }}
                  disabled={!isTrialPermission && !isSubscriptionPermission } 
                  
                />
              </div>
            </div>
            <div className={parentStyles.property}>
              <label>Grace Period</label>
              <input
                type="date"
                name="payment_due_date"
                value={paymentDueDate}
                onChange={(e) => {
                  setPaymentDueDate(e.target.value);
                  const temp = updatePayload;
                  temp["payment_due_date"] = e.target.value;
                  setUpdatePayload(temp);
                  setDisabled(false);
                }}
                disabled = {!isSubscriptionPermission}
              />
            </div>
            <div className={parentStyles.property}>
              <label>Chargebee ID</label>
              <input
                type="text"
                name="chargebee_id"
                value={chargebeeID}
                onChange={(e) => {
                  setChargebeeID(e.target.value);
                  const temp = updatePayload;
                  temp["chargebee_id"] = e.target.value;
                  setUpdatePayload(temp);
                  setDisabled(false);
                }}
                disabled = {!isSubscriptionPermission}
              />
            </div>
            <div className={parentStyles.property}>
              <label>Chargebee Customer ID</label>
              <input
                type="text"
                name="chargebee_customer_id"
                value={chargebeeCustomerID}
                onChange={(e) => {
                  setChargebeeCustomerID(e.target.value);
                  const temp = updatePayload;
                  temp["chargebee_customer_id"] = e.target.value;
                  setUpdatePayload(temp);
                  setDisabled(false);
                }}
                disabled = {!isSubscriptionPermission}
              />
            </div>
          </div>
          <div className={parentStyles.accountSettings}>
            <label className={parentStyles.mainheading}>
              Licenses
              <Button sx={{ color: "#34CB65" }} onClick={handleManageModal} disabled = {!isSubscriptionPermission}>
                ( Manage )
              </Button>
            </label>
            <br />
            <table className={styles.licenseTable}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Discount Price</th>
                  <th>Prorated Price</th>
                  <th>Total</th>
                  <th>Free</th>
                  <th>Deleted</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(planLicenses).map(
                  ([licenseType, licenseDetails]) => (
                    <tr key={licenseType}>
                      <td>{licenseType}</td>
                      <td>{licenseDetails.discountedPrice}</td>
                      <td>{licenseDetails.proratedPrice}</td>
                      <td>{licenseDetails.total}</td>
                      <td>{licenseDetails.free}</td>
                      <td>{licenseDetails.deleted}</td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>

            <div className={parentStyles.property}>
              <label>
                Credit Balance {`(${details?.credit?.currency || ""})`}
              </label>
              <div className={parentStyles.clm}>
                <input
                  type="text"
                  name="credit_balance"
                  value={credits}
                  onChange={(e) => {
                    setCredits(e.target.value);
                    const temp = updatePayload;
                    temp["credit"] = e.target.value;
                    setUpdatePayload(temp);
                    setDisabled(false);
                  }}
                  disabled = {!isSubscriptionPermission}
                />
                </div>
                </div>
                <div className={styles.reportDownload}>
                  <label>
                  Credit Usage Report
                  </label>
                  <div className={parentStyles.clm}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangePicker 
                        sx={localStyles.dateRange}
                        slots={{ field: SingleInputDateRangeField }}
                        value={[dayjs(startDate), dayjs(endDate)]}
                        onChange={(newValue) => {
                          setStartDate(newValue[0].format("YYYY-MM-DD"));
                          setEndDate(newValue[1].format("YYYY-MM-DD"));
                        }}
                      />
                    </LocalizationProvider>
                    <Button
                      size="small"
                      sx={{
                        bgcolor: "#34cb65",
                        marginLeft: "5px",
                        height: '44px',
                        marginTop: '2px',
                        ":hover": {
                          bgcolor: "#28994B",
                          color: "white",
                        },
                      }}
                      variant="contained"
                      disabled = {!startDate || !endDate}
                      onClick = {handleDownloadCreditUsageReport}
                    >
                    <DownloadIcon />
                  </Button>
                </div>
            </div>
          </div>
          <div className={parentStyles.accountSettings}>
            <label className={parentStyles.mainheading}>
              Discounts
            </label>
            <div className={parentStyles.property}>
              {Object.entries(discounts).map(([category, value]) => (
                <div key={category}>
                  <label><em>{category}</em></label>
                  {typeof value === "object" ? (
                    Object.entries(value).map(([key, price]) => (
                      <div key={`${category}-${key}`}>
                        <input
                          type="text"
                          value={key}
                          onChange={(e) => {
                            handleInputChange(category, key, e.target.value);
                            setDisabled(false);
                          }}
                          disabled = {!isSubscriptionPermission}
                        />
                        <input
                          type="number"
                          value={price}
                          style={{ width: "50px" }}
                          onChange={(e) => {
                            handleInputChange(category, key, e.target.value);
                            setDisabled(false);
                          }}
                          disabled = {!isSubscriptionPermission}
                        />
                      </div>
                    ))
                  ) : (
                    <div>
                      <input
                        type="text"
                        value={category}
                        onChange={(e) => {
                          handleInputChange(category, category, e.target.value);
                          setDisabled(false);
                        }}
                        disabled = {!isSubscriptionPermission}
                      />
                      <input
                        type="number"
                        value={value}
                        style={{ width: "50px" }}
                        onChange={(e) => {
                          handleInputChange(category, category, e.target.value);
                          setDisabled(false);
                        }}
                        disabled = {!isSubscriptionPermission}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={parentStyles.accountSettings}>
            <Button
              className={parentStyles.bts}
              variant="contained"
              style={{
                fontSize: 14,
                height: "max-content",
                marginTop: "10px",
                marginBottom: "10px",
                width: "max-content",
              }}
              disabled={["ACTIVE"].includes(status) || credits}
              sx={{
                bgcolor: "#34cb65",
                ":hover": {
                  bgcolor: "#28994B",
                  color: "white",
                },
              }}
              onClick={() => setShowTrialModal(true)}
            >
              Start Trial
            </Button>
            <div className={parentStyles.updateButtons}>
              <Button
                color="success"
                disabled={disabled}
                variant="outlined"
                size="medium"
                style={{
                  fontSize: 14,
                  height: "max-content",
                  marginTop: "10px",
                  width: "max-content",
                }}
                onClick={handleDiscardChanges}
              >
                Cancel
              </Button>
              <Button
                label="Save"
                color="success"
                variant="contained"
                style={{
                  fontSize: 14,
                  height: "max-content",
                  marginTop: "10px",
                  width: "max-content",
                }}
                sx={{
                  bgcolor: "#34cb65",
                  ":hover": {
                    bgcolor: "lightgreen",
                    color: "white",
                  },
                }}
                disabled={disabled}
                onClick={handleSaveChanges}
              >
                Save
              </Button>
            </div>
            <CustomModal
              heading="Start Trial"
              visible={showTrialModal}
              closeModal={() => setShowTrialModal(false)}
              bodyStyles={{
                width: "340px",
                padding: "1em",
              }}
              body={
                <div className={parentStyles.vnForm}>
                  <label>
                    <FormControl style={{ width: "100%", marginTop: "20px" }}>
                      Location
                      <Locations setSelectedLocation={setTrialLocation} />
                      <div>
                        <Button
                          sx={{
                            marginRight: "10px",
                            bgcolor: "#36cb64",
                            color: "white",
                            ":hover": {
                              bgcolor: "#28994B",
                            },
                          }}
                          className={parentStyles.button}
                          variant="outlined"
                          onClick={handleInitiateTrial}
                        >
                          Start trial
                        </Button>
                        <Button
                          className={parentStyles.button}
                          variant="outlined"
                          color="success"
                          onClick={() => setShowTrialModal(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </FormControl>
                  </label>
                </div>
              }
            />
          </div>
        </div>
      )}
      <CustomModal
        heading="Manage Licenses"
        visible={showManageModal}
        closeModal={handleManageModal}
        bodyStyles={{
          width: "404px",
          height: "440px",
        }}
        body={
          <div>
            <div className={styles.vnForm}>
              <div className={styles.MLicences}>
                Select Licenses
                <select onChange={(e) => setSelectedLicense(e.target.value)}>
                  <option value="" disabled selected>Select</option>
                  <option value="Plan"> Plan</option>
                  <option value="Viewer"> Viewer</option>
                  <option value="Autodial"> Autodial</option>
                  <option value="Browser Calling">Browser Calling</option>
                </select>
              </div>
              <div className={styles.MLicences}>
                Add/Remove Free Licenses
                <select onChange={(e) => setSelectedAction(e.target.value)}>
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="add">Add Free Licenses</option>
                  <option value="remove">Remove Free License</option>
                </select>
                <input
                  type="number"
                  value={selectedQuantity}
                  onChange={(e) =>
                    setSelectedQuantity(parseInt(e.target.value))
                  }
                  style={{ marginTop: "10px" }}
                />
              </div>
              <div className={styles.MLicences}>
                <Button
                  sx={{
                    bgcolor: "#36cb64",
                    color: "white",
                    marginLeft: "130px",
                    ":hover": {
                      bgcolor: "#28994B",
                    },
                  }}
                  className={parentStyles.button}
                  variant="outlined"
                  onClick={handleUpdateLicenses}
                  style={{ marginTop: "10px" }}
                >
                  Update Licenses
                </Button>
              </div>
            </div>
          </div>
        }
      />

      {showSnackbar && (
        <CustomSnackbar
          setShowSnackbar={setShowSnackbar}
          message={response.message}
          type={response.success ? "success" : "error"}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
}

const localStyles = {
  dateRange: {
    minWidth: '45%',
    maxWidth:'65%', 
    '.MuiInputBase-input.MuiOutlinedInput-input':{
      padding:'12px',
    },

  }
}

export default BillingDetails;
